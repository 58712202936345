export enum DiscontTypeOptions {
  PERCENTUAL = 'percentual',
  NOMINAL = 'norminal',
}

export interface IGroup {
  _id?: string;
  store_id: number;
  name: string;
  description: string;
  default_image: File | null;
  category: number[];
  products: IGroupProduct[];
  apply_discount: boolean;
  promotion_id?: string;
  show_buy_together: boolean;
  product_kit: boolean;
  external_product_id?: number;
  enabled_choice: boolean;
  active: boolean;
  file_upload?: any;
  categories?: { [key: number]: string };
  createdAt?: string;
  updatedAt?: string;
}

export interface IGroupProduct {
  name: string;
  order: number;
  show_buy_together_in_page?: boolean;
  product_id: number;
  variant_id?: number | null;
  variant_json?: string | null;
  fixed_option0?: string | null;
  fixed_option1?: string | null;
  fixed_option2?: string | null;
  default_image: string;
  price: number;
  discount_type: DiscontTypeOptions;
  discount_value: number;
  quantity: number;
  group_quantity?: boolean;
  display_separate_quantity?: boolean;
  lang?: string;

  // Campos de uso visual
  variations?: any[];
  description?: string;
}

export const EmptyGroup: IGroup = {
  store_id: -1,
  name: '',
  description: '',
  default_image: null,
  category: [],
  products: [],
  apply_discount: true,
  show_buy_together: true,
  product_kit: true,
  enabled_choice: false,
  active: true,
};
