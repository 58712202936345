import React, { useEffect, useRef, useState } from 'react';
import { Page, Layout, DataList } from '@nimbus-ds/patterns';
import { Alert, Box, Tag, Text, Title } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import apiMDInstance from '../../utils/apiMiddlewareUtils';
import { IAppNews } from '../../lib/interfaces/news.interface';
import i18n from '../../lib/i18n/i18n';
import { formatDate } from '../../utils/formatUtils';

const NewsPage: React.FC = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState<IAppNews[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement[]>([]); // Referência única para armazenar múltiplos elementos

  useEffect(() => {
    apiMDInstance(`/news/show`)
      .then(({ data, status }) => {
        if (status === 200 && data) {
          setNews(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (news) {
      news.forEach((data: any, index) => {
        const lang = i18n.language;
        const content = data[`content_${lang}`] || data.cotent_pt;

        if (containerRef.current[index]) {
          containerRef.current[index].innerHTML = content; // Atualiza o HTML nativamente
        }
      });
    }
  }, [news]);

  const getByLang = (data: any, type: 'title') => {
    const lang = i18n.language;
    return type === 'title' ? data[`title_${lang}`] || data.title_pt : '';
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('NewsPage.Title')}
          subtitle={`${t('NewsPage.Subtitle')}`}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="2">
                <Alert appearance="primary">{t(`NewsPage.Description`)}</Alert>
                <DataList>
                  {loading ? (
                    <DataList.Row gap="1">
                      <Box display="flex" justifyContent="space-between">
                        <Title.Skeleton width="50%" />
                        <Text.Skeleton width="10%" />
                      </Box>
                    </DataList.Row>
                  ) : news ? (
                    news.map((data, i) => (
                      <DataList.Row key={i} gap="4">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" gap="3" alignItems="center">
                            <Tag
                              appearance={
                                data.type === 'news'
                                  ? 'primary'
                                  : data.type === 'bugfix'
                                    ? 'danger'
                                    : 'warning'
                              }
                            >
                              {data.type}
                            </Tag>
                            <Title as="h4" fontWeight="medium">
                              {getByLang(data, 'title')}
                            </Title>
                          </Box>
                          <Text>
                            {formatDate(`${data.createdAt}`, 'numeric')}
                          </Text>
                        </Box>
                        <Text as="span">
                          <Box
                            ref={(el) => {
                              if (el) containerRef.current[i] = el;
                            }}
                          ></Box>
                        </Text>
                      </DataList.Row>
                    ))
                  ) : (
                    <></>
                  )}
                </DataList>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default NewsPage;
