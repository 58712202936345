import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import nexo from '../../nexoClient';

import {
  Page,
  Layout,
  FormField,
  EmptyMessage,
  InteractiveList,
  DataList,
  ThumbnailWithAction,
  HelpLink,
} from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FileUploader,
  Icon,
  IconButton,
  Input,
  Label,
  Link,
  Pagination,
  Select,
  Sidebar,
  Spinner,
  Tag,
  Text,
  Textarea,
  Thumbnail,
  Title,
  Toggle,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import {
  IGroup,
  EmptyGroup,
  DiscontTypeOptions,
} from '../../lib/interfaces/groups.interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BoxPackedIcon,
  CheckCircleIcon,
  DiscountCircleIcon,
  DragDotsIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  MoneyIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  SearchIcon,
  TrashIcon,
  UndoIcon,
} from '@nimbus-ds/icons';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

const GroupPage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
  const [loadingProductsDescriptions, setLoadingProductsDescriptions] =
    useState<boolean>(false);
  const [openSearchProdut, setOpenSearchProdut] = useState<boolean>(false);
  const [openSearchCategory, setOpenSearchCategory] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroup>({ ...EmptyGroup });
  const [products, setProducts] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [productsMeta, setProductsMeta] = useState<IPaginateMeta>();
  const [categoryMeta, setCategoryMeta] = useState<IPaginateMeta>();
  const [searchProductQuery, setSearchProductQuery] = useState<string>('');
  const [searchCategoryQuery, setSearchCategoryQuery] = useState<string>('');
  const [descriptionChange, setDescriptionChange] = useState<boolean>(false);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  useEffect(() => {
    group.categories = [];
    group.products = [];
    setGroup(group);
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    if (id && id.length) {
      getGroup();
    }
    setLoading(false);
  }, []);

  const handleChangeProductQuerySearch = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchProductQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      searchProducts(productsMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const handleChangeCategoryQuerySearch = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchCategoryQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      searchCategories(categoryMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 1.5 * 1024 * 1024) {
        addToast({
          id: 'error_image' + new Date().toISOString(),
          type: 'danger',
          text: `${t('GroupPage.toasts.upload_image.error_size')}`,
          position: 4,
        });
        return;
      }

      // Verifica o tipo do arquivo
      const validTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/gif',
        'image/webp',
      ];
      if (!validTypes.includes(file.type)) {
        addToast({
          id: 'error_image' + new Date().toISOString(),
          type: 'danger',
          text: `${t('GroupPage.toasts.upload_image.error_type')}`,
          position: 4,
        });
        return;
      }

      const reader = new FileReader();
      group.default_image = file;
      reader.onloadend = () => {
        const base64String = reader.result as string;
        group.file_upload = base64String;
        setGroup({ ...group });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeGroup = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;
    const updatedData: any = { ...group };
    let currentField = updatedData;
    const fieldPath = name.split('.');

    fieldPath.forEach((field, index) => {
      if (index === fieldPath.length - 1) {
        if (field in currentField) {
          if (type === 'checkbox' && event.target instanceof HTMLInputElement) {
            currentField[field] = event.target.checked;
          } else {
            currentField[field] = value;

            if (field === 'variant_id') {
              for (let x = 0; x < currentField.variations.length; x++) {
                if (
                  parseInt(currentField.variations[x].id) === parseInt(value)
                ) {
                  currentField.variant_json = JSON.stringify(
                    currentField.variations[x].values,
                  );
                }
                console.log(currentField.variant_json);
              }
            }
          }
        }
      } else {
        currentField = currentField[field];
      }
    });

    setGroup(updatedData);
  };

  const getGroup = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/group/${id}`);
      if (result.data !== null) {
        const resultGroup = result.data as IGroup;

        if (
          resultGroup &&
          resultGroup.products &&
          resultGroup.products.length
        ) {
          for (let x = 0; x < resultGroup.products.length; x++) {
            if (!('show_buy_together_in_page' in resultGroup.products[x])) {
              resultGroup.products[x].show_buy_together_in_page = true;
            }
          }
        }

        setGroup({ ...resultGroup });
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('GroupPage.toasts.get_group_error')}`,
        position: 4,
      });
    }
    setLoading(false);
  };

  const searchProducts = async (page = 1, reset = false) => {
    setLoadingProducts(true);
    try {
      const result = await apiInstance.get(
        `/products?q=${reset ? '' : searchProductQuery}&page=${page}`,
      );
      if (result.data !== null) {
        setProducts(result.data.result);
        setProductsMeta(result.data.meta as IPaginateMeta);
      }
    } catch (error) {
      setProducts([]);
    }
    setLoadingProducts(false);
  };

  const searchCategories = async (page = 1) => {
    setLoadingCategories(true);
    try {
      const result = await apiInstance.get(
        `/categories?q=${searchCategoryQuery}&page=${page}`,
      );
      if (result.data !== null && result.data.result.length) {
        const uniqueData: any[] = [];
        const ids = new Set();

        result.data.result.forEach((item: any) => {
          if (!ids.has(item.id)) {
            uniqueData.push(item);
            ids.add(item.id);
          }
        });

        setCategories(uniqueData);
        setCategoryMeta(result.data.meta as IPaginateMeta);
      }
    } catch (error) {
      setCategories([]);
    }
    setLoadingCategories(false);
  };

  const jsonToFormData = (
    data: Record<string, any>,
    formData?: FormData,
    parentKey?: string,
  ): FormData => {
    formData = formData || new FormData();

    if ((parentKey && typeof data === 'number') || typeof data === 'string') {
      formData.append(`${parentKey}`, data);
    } else {
      for (const key in data) {
        if (key in data) {
          const value = data[key];
          const formKey = parentKey ? `${parentKey}[${key}]` : key;

          if (value instanceof File || value instanceof Blob) {
            formData.append(formKey, value, key);
          } else if (typeof value === 'object' && !Array.isArray(value)) {
            jsonToFormData(value, formData, formKey);
          } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              console.log(item, `${formKey}[${index}]`);
              jsonToFormData(item, formData, `${formKey}[${index}]`);
            });
          } else {
            formData.append(formKey, value);
          }
        }
      }
    }

    return formData;
  };

  const handleSubmit = async () => {
    try {
      // setLoading(true);

      const payload_image: { file: File | null } = { file: null };
      const payload: { [key: string]: any } = { ...group };
      if (!payload.product_kit) {
        payload.default_image = null;
        payload.description = '';
        payload.category = '';
      } else {
        const ids: number[] = [];
        for (const catId in payload.categories) {
          ids.push(Number(catId));
        }
        payload.category = ids;
        payload_image.file = payload.default_image;
        payload.default_image = null;
      }

      delete payload.lang;
      delete payload.categories;
      delete payload.file_upload;

      for (let x = 0; x < payload.products.length; x++) {
        payload.products[x].variant_id = parseInt(
          payload.products[x].variant_id,
        );
        delete payload.products[x].description;
        delete payload.products[x].variations;
        // delete payload.products[x].variant_json;
        // delete payload.products[x].name;
        // delete payload.products[x].default_image;
      }

      let result;
      if (id) {
        result = await apiInstance.put(`/group/${id}`, payload);
      } else {
        result = await apiInstance.post(`/group`, payload);

        if (result.status === 201 && payload_image.file !== null) {
          const data = result.data;
          const { _id, external_product_id } = data;
          try {
            const formdataImage = new FormData();
            formdataImage.append('file', payload_image.file, 'file');
            const result_upload = await apiInstance.post(
              `/group/image/group/${_id}/product/${external_product_id}`,
              formdataImage,
            );
            console.log(result_upload);
          } catch (error) {
            console.log(error);
          }
        }
      }
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `${t('GroupPage.toasts.create_success')}`,
        position: 4,
      });
      setLoading(false);
      console.log(id, result);
      if (id === undefined && typeof result === 'object' && result.data) {
        navigate(`/`);
      }
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('GroupPage.toasts.create_error')}`,
        position: 4,
      });
      setLoading(false);
    }
  };

  const handleRemoveProductForGroup = (index: number) => {
    if (group.products[index]) {
      group.products.splice(index, 1);
      setGroup({ ...group });
      concatProductsDescriptions();

      addToast({
        type: 'success',
        text: t('GroupPage.toasts.remove_product_group_success'),
        id: 'handleRemoveProductForGroup' + new Date().toISOString(),
        position: 4,
      });
      return false;
    }
    addToast({
      type: 'danger',
      text: t('GroupPage.toasts.remove_product_group_error'),
      id: 'handleRemoveProductForGroup' + new Date().toISOString(),
      position: 4,
    });
    return false;
  };

  const handleRemoveCategoryForGroup = (id: number) => {
    if (group.categories && id in group.categories) {
      delete group.categories[id];
      setGroup({ ...group });
    }
    return false;
  };

  const handleAddCategoryToGroup = async (
    event: ChangeEvent<HTMLInputElement>,
    category: any,
  ) => {
    const { checked } = event.target;
    if (checked) {
      if (!group.categories) {
        group.categories = {};
      }
      group.categories[category.id] = category.name[category.default_lang];
    } else {
      if (group.categories && category.id in group.categories) {
        delete group.categories[category.id];
      }
    }
    setGroup({ ...group });
  };

  const handleAddProductToGroup = async (
    event: ChangeEvent<HTMLInputElement>,
    product: any,
  ) => {
    console.log(`product`, product);
    const { checked } = event.target;
    if (checked) {
      const lang = product.default_lang || 'pt';
      group.products.push({
        name: product.name[lang],
        order: group.products.length,
        show_buy_together_in_page: true,
        product_id: product.id,
        variant_id: null,
        variant_json: null,
        fixed_option0: null,
        fixed_option1: null,
        fixed_option2: null,
        default_image:
          product.images && product.images.length ? product.images[0].src : '',
        price: 0,
        discount_type: DiscontTypeOptions.PERCENTUAL,
        discount_value: 0,
        quantity: 1,
        group_quantity: true,
        display_separate_quantity: false,
        lang: lang,
        variations: product.variants,
        description: '',
        // description: product.description[lang],
      });
    } else {
      for (let x = 0; x < group.products.length; x++) {
        if (group.products[x].product_id === product.id) {
          group.products.splice(x, 1);
        }
      }
    }
    setGroup({ ...group });
    // concatProductsDescriptions();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const productsCopy = [...group.products];
    const [removedField] = productsCopy.splice(source.index, 1);
    productsCopy.splice(destination.index, 0, removedField);

    for (let x = 0; x < productsCopy.length; x++) {
      productsCopy[x].order = x + 1;
    }

    setGroup({ ...group, products: productsCopy });
    // concatProductsDescriptions();
  };

  const handleOpenSidebarProduct = () => {
    searchProducts();
    setOpenSearchProdut(true);
  };

  const concatProductsDescriptions = async (ignoreFlag = false) => {
    if (descriptionChange && !ignoreFlag) {
      return false;
    }
    setLoadingProductsDescriptions(true);
    const descriptions: string[] = [];
    const ids: number[] = [];
    for (let x = 0; x < group.products.length; x++) {
      const product = group.products[x];
      ids.push(product.product_id);
      // if (product.description && product.description !== '') {
      //   description.push(`
      //     <h3>${product.name}</h3>
      //     ${product.description}
      //   `);
      // }
    }

    if (ids.length) {
      const result = await apiInstance.get(
        `/products/description?ids=${ids.join(',')}`,
      );

      if (result.status === 200) {
        const descriptionsList = result.data as { [key: string]: any }[];

        if (descriptionsList.length) {
          for (let x = 0; x < descriptionsList.length; x++) {
            const product = descriptionsList[x];
            descriptions.push(`
                <h3>${product.name[product.lang]}</h3>
                ${product.description[product.lang]}
                `);
          }
          const separator = '<div style="padding: 30px 0;"><hr /></div>';
          group.description = descriptions.join(separator);
          setGroup({ ...group });
        }
      }
    }
    setLoadingProductsDescriptions(false);
  };

  const handleOpenSidebarCategory = () => {
    searchCategories();
    setOpenSearchCategory(true);
  };

  const prepareVariantLabel = (
    options: { [key: string]: string }[],
    lang = 'pt',
  ): string => {
    const result: string[] = [];
    for (let x = 0; x < options.length; x++) {
      result.push(options[x][lang]);
    }
    return result.join(` / `);
  };

  const getUniqueVariantGroups = (
    defaultLang: string,
    variants: any[],
  ): string[][] => {
    const groups: Set<string>[] = [];

    variants.forEach((variant) => {
      variant.values.forEach((value: any, index: number) => {
        if (!groups[index]) {
          groups[index] = new Set();
        }
        groups[index].add(value[defaultLang]);
      });
    });

    return groups.map((group) => Array.from(group)); // Converte cada Set para Array
  };

  return (
    <>
      <Page>
        <Page.Header
          title={`${t(`GroupPage.title.${id ? 'update' : 'create'}`)} ${
            group.name ? `'${group.name}'` : ''
          }`}
          buttonStack={
            <Button appearance="primary" onClick={handleOpenSidebarProduct}>
              <Icon source={<PlusCircleIcon />} color="currentColor" />
              {t(`GroupPage.products.cta_add`)}
            </Button>
          }
        />
        <Page.Body>
          <Layout columns="2 - symmetric" gap="6">
            {/* Section Config */}
            <Layout.Section>
              <Box display="grid" gap="6">
                {/* DADOS DO KIT */}
                <Card>
                  <Card.Header>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Title as="h4">{t(`GroupPage.cards.config.title`)}</Title>
                      <Toggle
                        name="active"
                        label={`${t(`GroupPage.form.fields.active.label`)}`}
                        disabled={loading || group === null}
                        active={group.active}
                        onChange={handleChangeGroup}
                      />
                    </Box>
                  </Card.Header>
                  <Card.Body>
                    <Box display="grid" gap="4">
                      <FormField
                        label={
                          <Box display="flex" gap="1">
                            <Label>
                              {t('GroupPage.form.fields.name.label')}
                            </Label>
                            <Tooltip
                              content={t(
                                'GroupPage.form.fields.name.labelHelper',
                              )}
                              position="right"
                            >
                              <Icon
                                source={<ExclamationCircleIcon size={14} />}
                                color="currentColor"
                              />
                            </Tooltip>
                          </Box>
                        }
                      >
                        <Input
                          disabled={loading}
                          id="name"
                          name="name"
                          onChange={handleChangeGroup}
                          value={group.name}
                          placeholder={`${t(
                            'GroupPage.form.fields.name.placeholder',
                          )}`}
                        />
                      </FormField>
                    </Box>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header title={`${t('GroupPage.cards.kit.title')}`} />
                  <Card.Body>
                    <Box display="grid" gap="6" width="100%">
                      <Checkbox
                        name="product_kit"
                        disabled={loading || id !== undefined}
                        checked={group.product_kit}
                        onChange={handleChangeGroup}
                        label={`${t(
                          'GroupPage.form.fields.product_kit.label',
                        )}`}
                      />
                      <Text>{`${t('GroupPage.cards.kit.message')}`}</Text>

                      {group.product_kit && !id ? (
                        <>
                          <Box
                            display="flex"
                            gap="2"
                            boxSizing="border-box"
                            width="100%"
                          >
                            {group.file_upload && group.file_upload !== '' ? (
                              <Box boxSizing="border-box">
                                <ThumbnailWithAction
                                  thumbnail={{
                                    aspectRatio: '1/1',
                                    width: '110px',
                                    alt: 'preview',
                                    src: group.file_upload,
                                  }}
                                >
                                  <IconButton
                                    source={<TrashIcon />}
                                    size="2rem"
                                    onClick={() => {
                                      group.file_upload = '';
                                      setGroup({ ...group });
                                    }}
                                  />
                                </ThumbnailWithAction>
                              </Box>
                            ) : (
                              <FileUploader
                                aspectRatio="none"
                                flexDirection="row"
                                height="110px"
                                accept="image/jpeg,image/gif,image/png,image/webp"
                                placeholder={
                                  group.file_upload && group.file_upload !== ''
                                    ? `${t(
                                        'GroupPage.form.fields.image.label_change',
                                      )}`
                                    : `${t(
                                        'GroupPage.form.fields.image.label',
                                      )}`
                                }
                                width="110px"
                                onChange={handleImageChange}
                              />
                            )}
                          </Box>
                          <Box
                            display="grid"
                            maxWidth="100%"
                            overflow="auto"
                            boxSizing="border-box"
                          >
                            <FormField
                              label={`${t(
                                'GroupPage.form.fields.description.label',
                              )}`}
                              helpIcon={ExclamationCircleIcon}
                              showHelpText={false}
                              helpText={`${t(
                                'GroupPage.form.fields.description.help_text',
                              )}`}
                            >
                              <Textarea
                                lines={15}
                                disabled={loading}
                                id="description"
                                name="description"
                                onChange={(e) => {
                                  handleChangeGroup(e);
                                  setDescriptionChange(true);
                                }}
                                value={group.description}
                              />
                            </FormField>
                            {group.products.length ? (
                              <Link
                                disabled={loadingProductsDescriptions}
                                appearance="primary"
                                as="button"
                                onClick={async () => {
                                  concatProductsDescriptions(true);
                                }}
                              >
                                {loadingProductsDescriptions
                                  ? `Gerando descrição`
                                  : t(
                                      'GroupPage.form.fields.description.cta_generate',
                                    )}
                                <Tooltip
                                  content={t(
                                    'GroupPage.form.fields.description.cta_generate_helper',
                                  )}
                                  position="right"
                                >
                                  <Icon
                                    source={<QuestionCircleIcon size={14} />}
                                    color="currentColor"
                                  />
                                </Tooltip>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box display="grid" gap="2">
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap="2"
                            >
                              <Label>
                                {`${t('GroupPage.form.fields.category.label')}`}
                              </Label>
                              <Link
                                as="button"
                                appearance="primary"
                                onClick={handleOpenSidebarCategory}
                              >
                                <Icon
                                  source={<PlusCircleIcon />}
                                  color="currentColor"
                                />
                                {t(`GroupPage.form.fields.category.cta_add`)}
                              </Link>
                            </Box>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              borderColor="neutral-surfaceHighlight"
                              borderWidth="1"
                              borderStyle="dashed"
                              padding="2"
                              borderRadius="2"
                              minHeight="42px"
                              gap="2"
                            >
                              {group.categories ? (
                                Object.keys(group.categories).map((key) => (
                                  <Chip
                                    key={key}
                                    icon={<CheckCircleIcon size={12} />}
                                    text={`${
                                      group.categories &&
                                      key in group.categories
                                        ? group.categories[Number(key)]
                                        : ''
                                    }`}
                                    removable
                                    onClick={() =>
                                      handleRemoveCategoryForGroup(Number(key))
                                    }
                                  />
                                ))
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Card.Body>
                </Card>
                {/* REGRAS */}
                <Card>
                  <Card.Header title={`${t(`GroupPage.cards.rules.title`)}`} />
                  <Card.Body>
                    <Box display="grid" gap="6">
                      <Checkbox
                        name="show_buy_together"
                        disabled={
                          loading || (id !== undefined && !group.product_kit)
                        }
                        checked={group.show_buy_together}
                        onChange={handleChangeGroup}
                        label={`${t(
                          'GroupPage.form.fields.show_buy_together.label',
                        )}`}
                      />

                      <Checkbox
                        name="apply_discount"
                        disabled={loading}
                        checked={group.apply_discount}
                        onChange={handleChangeGroup}
                        label={`${t(
                          'GroupPage.form.fields.apply_discount.label',
                        )}`}
                      />
                      <Checkbox
                        name="enabled_choice"
                        disabled={loading}
                        checked={group.enabled_choice}
                        onChange={handleChangeGroup}
                        label={`${t(
                          'GroupPage.form.fields.enabled_choice.label',
                        )}`}
                      />
                    </Box>
                  </Card.Body>
                </Card>
              </Box>
              <Box display="flex" marginY="2" justifyContent="flex-end">
                <Tooltip
                  content={
                    group.products.length < 2
                      ? t(`GroupPage.products.tooltips.cta_save.block`)
                      : group.product_kit
                        ? t(`GroupPage.products.tooltips.cta_save.is_kit`)
                        : t(
                            `GroupPage.products.tooltips.cta_save.is_buy_together`,
                          )
                  }
                  position="top"
                >
                  <Button
                    disabled={
                      loading ||
                      (group.products.length === 1 &&
                        group.products[0].quantity < 2) ||
                      group.products.length === 0
                    }
                    appearance="primary"
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <Spinner color="currentColor" size="small" />
                    ) : (
                      ''
                    )}
                    {t('general.save')}
                  </Button>
                </Tooltip>
                {/* <Textarea
                  value={JSON.stringify(group)}
                  id="teste"
                  name="texte"
                ></Textarea> */}
              </Box>
            </Layout.Section>
            {/* Section Products */}
            <Layout.Section>
              {group.products.length ? (
                <Card padding="none">
                  <Card.Header
                    padding="base"
                    title={`${t(`GroupPage.title.products`)} (${
                      group.products.length
                    })`}
                  />
                  <Card.Body>
                    <DataList>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="fieldList">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {group.products.map((product, i) => (
                                <Draggable
                                  key={i}
                                  draggableId={`product-${i}`}
                                  index={i}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <DataList.Row key={i} gap="1">
                                        <Box
                                          ref={useRef<HTMLDivElement>(null)}
                                          key={i}
                                          display="grid"
                                          gap="2"
                                          draggable="true"
                                          id={`table-draggable-${i}`}
                                          boxSizing="border-box"
                                        >
                                          {/* BOX NOME */}
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                          >
                                            <Box
                                              display="flex"
                                              justifyContent="flex-start"
                                              alignItems="center"
                                              gap="2"
                                            >
                                              <Icon source={<DragDotsIcon />} />
                                              {product.default_image !== '' ? (
                                                <Thumbnail
                                                  src={product.default_image}
                                                  alt={product.name}
                                                  width="36px"
                                                />
                                              ) : (
                                                <Thumbnail
                                                  alt={product.name}
                                                  width="36px"
                                                />
                                              )}
                                              <Text
                                                fontWeight="medium"
                                                color="primary-interactive"
                                              >
                                                {product.name}
                                                {product.variant_id &&
                                                product.variant_json
                                                  ? ` (${prepareVariantLabel(
                                                      JSON.parse(
                                                        product.variant_json ||
                                                          '{}',
                                                      ) as {
                                                        [key: string]: string;
                                                      }[],
                                                      product.lang,
                                                    )})`
                                                  : ``}
                                              </Text>
                                            </Box>
                                            <Tooltip
                                              content={`${t(
                                                `GroupPage.products.tooltips.remove_product`,
                                              )}`}
                                              position="left"
                                            >
                                              <IconButton
                                                size="36px"
                                                source={
                                                  <TrashIcon size="small" />
                                                }
                                                disabled={loading}
                                                type="button"
                                                onClick={() =>
                                                  handleRemoveProductForGroup(i)
                                                }
                                              />
                                            </Tooltip>
                                          </Box>
                                          <Box
                                            display="flex"
                                            gap="2"
                                            paddingLeft="6"
                                          >
                                            {product.variations &&
                                            product.variations?.length > 1 ? (
                                              <Box display="grid" gap="2">
                                                <Text>{`${t('GroupPage.form.fields.products.variant_id.label')}`}</Text>
                                                <Box
                                                  display="flex"
                                                  gap="2"
                                                  alignItems="center"
                                                >
                                                  {getUniqueVariantGroups(
                                                    product.lang || 'pt',
                                                    product.variations,
                                                  ).map((options, index) => (
                                                    <Select
                                                      key={index}
                                                      disabled={
                                                        loading ||
                                                        !group.apply_discount
                                                      }
                                                      id={`products.${i}.fixed_option${index}`}
                                                      name={`products.${i}.fixed_option${index}`}
                                                      onChange={
                                                        handleChangeGroup
                                                      }
                                                    >
                                                      <Select.Option
                                                        value={''}
                                                        label={`${t(
                                                          'GroupPage.form.fields.products.variant_id.option_all',
                                                        )}`}
                                                      />
                                                      {options.map(
                                                        (option, optIndex) => (
                                                          <Select.Option
                                                            key={optIndex}
                                                            value={option}
                                                            label={option}
                                                          />
                                                        ),
                                                      )}
                                                    </Select>
                                                  ))}
                                                </Box>
                                                )
                                              </Box>
                                            ) : (
                                              <Box display="grid" width="100%">
                                                {product.fixed_option0 ||
                                                product.fixed_option1 ||
                                                product.fixed_option0 ? (
                                                  <Box
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    gap="4"
                                                  >
                                                    <Text fontSize="caption">
                                                      <Text
                                                        fontSize="caption"
                                                        as="span"
                                                        fontWeight="bold"
                                                      >
                                                        Variação 1:{` `}
                                                      </Text>
                                                      {product.fixed_option0 !==
                                                      null
                                                        ? product.fixed_option0
                                                        : t(
                                                            'GroupPage.form.fields.products.variant_id.option_all',
                                                          )}
                                                    </Text>
                                                    <Text fontSize="caption">
                                                      <Text
                                                        fontSize="caption"
                                                        as="span"
                                                        fontWeight="bold"
                                                      >
                                                        Variação 2:{` `}
                                                      </Text>
                                                      {product.fixed_option1 !==
                                                      null
                                                        ? product.fixed_option1
                                                        : t(
                                                            'GroupPage.form.fields.products.variant_id.option_all',
                                                          )}
                                                    </Text>
                                                    <Text fontSize="caption">
                                                      <Text
                                                        fontSize="caption"
                                                        as="span"
                                                        fontWeight="bold"
                                                      >
                                                        Variação 3:{` `}
                                                      </Text>
                                                      {product.fixed_option2 !==
                                                      null
                                                        ? product.fixed_option2
                                                        : t(
                                                            'GroupPage.form.fields.products.variant_id.option_all',
                                                          )}
                                                    </Text>
                                                  </Box>
                                                ) : product.variant_json ? (
                                                  <Text>
                                                    {`Variação: `}
                                                    {prepareVariantLabel(
                                                      JSON.parse(
                                                        product.variant_json ||
                                                          '{}',
                                                      ) as {
                                                        [key: string]: string;
                                                      }[],
                                                      product.lang,
                                                    )}
                                                  </Text>
                                                ) : (
                                                  <Tag appearance="neutral">
                                                    <BoxPackedIcon size={12} />
                                                    <Text color="neutral-textLow">
                                                      Produto simple
                                                    </Text>
                                                  </Tag>
                                                )}
                                              </Box>
                                            )}
                                          </Box>

                                          {/* BOX INPUTS */}
                                          <Box
                                            display="flex"
                                            gap="3"
                                            paddingLeft="6"
                                            alignItems="flex-end"
                                            boxSizing="border-box"
                                          >
                                            {/* BOX QUANTIDADE */}
                                            <Box
                                              boxSizing="border-box"
                                              width="18%"
                                            >
                                              <FormField
                                                label={`${t(
                                                  'GroupPage.form.fields.products.quantity.label',
                                                )}`}
                                              >
                                                <Input
                                                  disabled={loading}
                                                  id={`products.${i}.quantity`}
                                                  name={`products.${i}.quantity`}
                                                  type="number"
                                                  min={1}
                                                  step={1}
                                                  onChange={handleChangeGroup}
                                                  value={product.quantity}
                                                />
                                              </FormField>
                                            </Box>

                                            {/* BOX TIPO DESCONTO */}
                                            <Box
                                              boxSizing="border-box"
                                              width="23%"
                                            >
                                              <FormField
                                                label={`${t(
                                                  'GroupPage.form.fields.products.discount_type.label',
                                                )}`}
                                              >
                                                <Select
                                                  disabled={
                                                    loading ||
                                                    !group.apply_discount
                                                  }
                                                  id={`products.${i}.discount_type`}
                                                  name={`products.${i}.discount_type`}
                                                  onChange={handleChangeGroup}
                                                  value={product.discount_type}
                                                >
                                                  <Select.Option
                                                    value={
                                                      DiscontTypeOptions.PERCENTUAL
                                                    }
                                                    label={`${t(
                                                      'GroupPage.form.fields.products.discount_type.option_1',
                                                    )}`}
                                                  />
                                                  <Select.Option
                                                    value={
                                                      DiscontTypeOptions.NOMINAL
                                                    }
                                                    label={`${t(
                                                      'GroupPage.form.fields.products.discount_type.option_2',
                                                    )}`}
                                                  />
                                                </Select>
                                              </FormField>
                                            </Box>

                                            {/* BOX VALOR DESCONTO */}
                                            <Box
                                              boxSizing="border-box"
                                              width="23%"
                                            >
                                              <FormField
                                                label={`${t(
                                                  'GroupPage.form.fields.products.discount_value.label',
                                                )}`}
                                              >
                                                <Input
                                                  disabled={
                                                    loading ||
                                                    !group.apply_discount
                                                  }
                                                  append={
                                                    <Icon
                                                      source={
                                                        <Icon
                                                          source={
                                                            product.discount_type ===
                                                            DiscontTypeOptions.NOMINAL ? (
                                                              <MoneyIcon />
                                                            ) : (
                                                              <DiscountCircleIcon />
                                                            )
                                                          }
                                                        />
                                                      }
                                                    />
                                                  }
                                                  appendPosition={
                                                    product.discount_type ===
                                                    DiscontTypeOptions.NOMINAL
                                                      ? 'start'
                                                      : 'end'
                                                  }
                                                  id={`products.${i}.discount_value`}
                                                  name={`products.${i}.discount_value`}
                                                  type="number"
                                                  min={0}
                                                  step={0.01}
                                                  onChange={handleChangeGroup}
                                                  value={product.discount_value}
                                                />
                                              </FormField>
                                            </Box>
                                          </Box>

                                          {group.show_buy_together ? (
                                            <Box
                                              paddingLeft="6"
                                              boxSizing="border-box"
                                            >
                                              <Toggle
                                                id={`products.${i}.show_buy_together_in_page`}
                                                name={`products.${i}.show_buy_together_in_page`}
                                                label={`${t(
                                                  'GroupPage.form.fields.products.show_buy_together_in_page.label',
                                                )}`}
                                                disabled={loading}
                                                active={
                                                  product.show_buy_together_in_page
                                                }
                                                onChange={handleChangeGroup}
                                              />
                                            </Box>
                                          ) : (
                                            <></>
                                          )}

                                          {product.quantity > 1 ? (
                                            <Box
                                              paddingLeft="6"
                                              boxSizing="border-box"
                                            >
                                              <Toggle
                                                id={`products.${i}.display_separate_quantity`}
                                                name={`products.${i}.display_separate_quantity`}
                                                label={`${t(
                                                  'GroupPage.form.fields.products.display_separate_quantity.label',
                                                )}`}
                                                disabled={loading}
                                                active={
                                                  product.display_separate_quantity
                                                }
                                                onChange={handleChangeGroup}
                                              />
                                            </Box>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </DataList.Row>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </DataList>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <Box padding="2">
                      <EmptyMessage
                        title={t('GroupPage.products.empty_message.title')}
                        text={`${t('GroupPage.products.empty_message.text')}`}
                        icon={<SearchIcon size={32} />}
                        actions={
                          <Link
                            appearance="primary"
                            onClick={handleOpenSidebarProduct}
                          >
                            <Icon
                              color="currentColor"
                              source={<PlusCircleIcon />}
                            />
                            {t('GroupPage.products.cta_add')}
                          </Link>
                        }
                      />
                    </Box>
                  </Card.Body>
                </Card>
              )}

              <HelpLink>
                <Link
                  as="a"
                  href="https://huapps.com.br/ajuda/compre-junto-pro/pt?tab=2"
                  target="_blank"
                  appearance="primary"
                  textDecoration="none"
                >
                  Mais informações sobre os grupos
                  <Icon source={<ExternalLinkIcon />} color="currentColor" />
                </Link>
              </HelpLink>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
      {/* BUSCA PRODUTOS */}
      <Sidebar
        padding="none"
        open={openSearchProdut}
        onRemove={() => setOpenSearchProdut(false)}
      >
        <Sidebar.Header padding="base">
          <Box display="grid" gap="2">
            <Title as="h4">{t(`GroupPage.sidebar.product.title`)}</Title>
            <Input.Search
              placeholder={`${t(
                `GroupPage.sidebar.product.search_placeholder`,
              )}`}
              onChange={handleChangeProductQuerySearch}
              value={searchProductQuery}
            />
          </Box>
        </Sidebar.Header>
        <Sidebar.Body>
          {loadingProducts ? (
            <InteractiveList>
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
            </InteractiveList>
          ) : products.length ? (
            <>
              <InteractiveList>
                {products.map((product: any, index: number) => (
                  <InteractiveList.CheckboxItem
                    key={index}
                    title={''}
                    showTitle={false}
                    checkbox={{
                      name: 'checkbox-element-' + index,
                      checked:
                        group.products.length &&
                        group.products.find(
                          (item) => item.product_id === product.id,
                        )
                          ? true
                          : false,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange: (event: ChangeEvent<HTMLInputElement>) =>
                        handleAddProductToGroup(event, product),
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="3">
                      {product.images.length ? (
                        <Thumbnail
                          src={product.images[0].src}
                          alt={product.name[product.default_lang]}
                          width="40px"
                        />
                      ) : (
                        <Thumbnail
                          alt={product.name[product.default_lang]}
                          width="40px"
                        />
                      )}
                      <Text>{product.name[product.default_lang]}</Text>
                    </Box>
                  </InteractiveList.CheckboxItem>
                ))}
              </InteractiveList>
              {productsMeta && productsMeta.pages > 1 ? (
                <Pagination
                  activePage={productsMeta.page}
                  onPageChange={(page) => searchProducts(page)}
                  pageCount={productsMeta.pages}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <EmptyMessage
              title={t('GroupPage.sidebar.product.empty_title')}
              text={`${t('GroupPage.sidebar.product.empty_text')}`}
              icon={<SearchIcon size={32} />}
              actions={
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSearchProductQuery('');
                    searchProducts(1, true);
                  }}
                >
                  <Icon color="currentColor" source={<UndoIcon />} />
                  {t('GroupPage.sidebar.product.empty_cta')}
                </Button>
              }
            />
          )}
        </Sidebar.Body>
        <Sidebar.Footer padding="base">
          <Box display="flex" justifyContent="flex-end">
            <Button
              appearance="primary"
              onClick={() => setOpenSearchProdut(false)}
            >
              {t('general.save')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>

      {/* BUSCA CATEGORIAS */}
      <Sidebar
        padding="none"
        open={openSearchCategory}
        onRemove={() => setOpenSearchCategory(false)}
      >
        <Sidebar.Header padding="base">
          <Box display="grid" gap="2">
            <Title as="h4">{t(`GroupPage.sidebar.category.title`)}</Title>
            <Input.Search
              placeholder={`${t(
                `GroupPage.sidebar.category.search_placeholder`,
              )}`}
              onChange={handleChangeCategoryQuerySearch}
              value={searchCategoryQuery}
            />
          </Box>
        </Sidebar.Header>
        <Sidebar.Body>
          {loadingCategories ? (
            <InteractiveList>
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
            </InteractiveList>
          ) : categories.length ? (
            <>
              <InteractiveList>
                {categories.map((category: any, index: number) => (
                  <InteractiveList.CheckboxItem
                    key={index}
                    title={category.name[category.default_lang]}
                    checkbox={{
                      name: 'checkbox-category-element-' + index,
                      checked:
                        group.categories && category.id in group.categories
                          ? true
                          : false,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange: (event: ChangeEvent<HTMLInputElement>) =>
                        handleAddCategoryToGroup(event, category),
                    }}
                  />
                ))}
              </InteractiveList>
              {categoryMeta && categoryMeta.pages > 1 ? (
                <Pagination
                  activePage={categoryMeta.page}
                  onPageChange={(page) => searchCategories(page)}
                  pageCount={categoryMeta.pages}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <EmptyMessage
              title={t('GroupPage.sidebar.category.empty_title')}
              text={`${t('GroupPage.sidebar.category.empty_text')}`}
              icon={<SearchIcon size={32} />}
              actions={
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSearchProductQuery('');
                    searchProducts(1, true);
                  }}
                >
                  <Icon color="currentColor" source={<UndoIcon />} />
                  {t('GroupPage.sidebar.category.empty_cta')}
                </Button>
              }
            />
          )}
        </Sidebar.Body>
        <Sidebar.Footer padding="base">
          <Box display="flex" justifyContent="flex-end">
            <Button
              appearance="primary"
              onClick={() => setOpenSearchCategory(false)}
            >
              {t('general.save')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>
    </>
  );
};

export default GroupPage;
